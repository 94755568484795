<template>
  <div class="collect-product">
    <div class="collect-product-content" :class="{on : pageLoad}">
        <!-- title -->
        <template v-if="type=='add'">
          <c-image iUrl="icon-success" iClass="icon" iWidth="36" iHeight="36"></c-image>
          <h1>收藏成功</h1>
          <h2>选择作品画板/若不选择则收藏至待归类下</h2>
        </template>

        <template v-else-if="type=='edit'">
          <c-image iUrl="icon-tag_2" iClass="icon" iWidth="36" iHeight="36"></c-image>
          <h1>重新选择画板</h1>
        </template>
       
         <!-- img -->
        <div class="img">
          <div class="collect-product-cover"></div>
          <van-image :src="previewImage" :width="100/37.5+'rem'" :height="100/37.5+'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
        </div>

        <!-- tags -->
        <div class="tag">
          <div class="tag_add" @click="onPageToAddTag">
            <c-image iUrl="icon-add_2" iClass="icon_add" iWidth="16" iHeight="16"></c-image>
            添加画板
          </div>

          <template v-if="tags.length > 0">
              <div class="tag_item" :class="{on:item.checked==1 ? true : false}" v-for="(item,index) in tags" :key="item.id" @click="onCheckTag(index)">{{item.label_name}}</div>
          </template>
        </div>

        <c-button bClass="btn" @btnClick="onSetTag">确定</c-button>
    </div>

  </div>
</template>

<script>
import { Image } from "vant";
import CImage from "../../components/CImage.vue";
import CButton from "../../components/form/CButton.vue";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    components: {
        [Image.name]: Image,
        [CImage.name]: CImage,
        [CButton.name]: CButton,
    },
    data() {
        return {
            pageLoad: false,
            type: "add",
            id: 0,
        };
    },
    computed: {
        ...mapState({
            previewImage: (state) =>
                state.collect.collectProductData.preview_image,
            tags: (state) => state.collect.collectProductData.label_list,
            fromRouteName: (state) => state.fromRouteName,
            os: (state) => state.os,
        }),
    },
    methods: {
        ...mapActions({
            requestLabelSetting: "collect/requestLabelSetting",
            requestResetInitLabels: "collect/requestResetInitLabels",
        }),
        ...mapMutations({
            setTagsCheck: "collect/SET_TAGS_CHECK",
        }),
        onPageToAddTag() {
            this.$router.push({ name: "add_tag" });
        },
        onSetTag() {
            let _arr = this.tags.filter((v) => {
                return v.checked == 1;
            });
            _arr = _arr.map((v) => {
                return v.id;
            });

            let _str = _arr.join(",");

            this.requestLabelSetting({
                works_id: this.id,
                opt_ids: _str,
            }).then(() => {
                this.$router.go(-1);
            });
        },
        onCheckTag(i) {
            let _check = this.tags[i].checked;
            if (_check == 0) this.setTagsCheck({ index: i, checked: 1 });
            else if (_check == 1) this.setTagsCheck({ index: i, checked: 0 });
        },
    },
    activated() {
        document.body.classList.add('bg-white')

        if (this.fromRouteName) {
            let _id = this.$route.params.id;
            this.pageLoad = false;
            if (_id) {
                this.id = _id;
                this.type = this.$route.params.type;
            }

            this.requestResetInitLabels({ works_id: this.id }).then(() => {
                this.pageLoad = true;
            });
        } else {
            this.$router.go(-1)
        }
    },
    deactivated() {
        document.body.classList.remove('bg-white')
    },
};
</script>

<style>
.collect-product-cover{width:100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 100;}
.collect-product{padding-top: 47px; height: 100vh; background: #fff;}
.collect-product-content{padding: 0 20px; opacity: 0; padding-bottom: 32px;}
.collect-product-content.on{opacity: 1;}
.collect-product-content .icon{margin: 32px auto 12px;}
.collect-product-content h1{color: #2E2E30;font-size: 20px;text-align: center; font-weight: normal;}
.collect-product-content h2{color: #666666;font-size: 14px; text-align: center; margin: 2px; font-weight: normal;}
.collect-product-content .img{width:100px; height: 100px; margin:24px auto 0; border-radius: 4px; overflow: hidden; background: #ededed;}
.collect-product-content .tag{display: flex; justify-content: flex-start; flex-wrap: wrap; align-items: flex-start; margin-top: 32px;}
.collect-product-content .tag_item{padding: 0 20px; height: 36px; line-height: 36px; border:1px solid #B3B3B3; border-radius: 4px;font-size: 14px;color: #B3B3B3; margin-right: 12px; margin-bottom: 12px;}
.collect-product-content .tag_item.on{border-color: #F85659; background: #F85659; color: #fff;}
.collect-product-content .tag_add{padding: 0px 20px; height: 36px; display: flex; justify-content: center; align-items: center;font-size: 14px;color: #666666; background: #EDEDED;border-radius: 4px; margin-right: 0.32rem;}
.collect-product-content .icon_add{margin-right: 4px;}
.collect-product-content .btn{margin-top:32px;}
</style>